import { createContext, useContext, PropsWithChildren } from "react";
import * as bulmaToast from 'bulma-toast'

const ToastContext = createContext<{toastInfo: (message: string) => void, toastSuccess: (message: string) => void, toastError: (message: string) => void}>(null!);

export const ToastProvider = (props: PropsWithChildren<{}>) => {

  const addToast = (message: string, type: bulmaToast.ToastType) => {
    bulmaToast.toast({
      duration: 10000,
      position: "bottom-right",
      closeOnClick: true,
      message: message,
      type: type,
      dismissible: true,
      animate: { in: 'fadeIn', out: 'fadeOut' },
      single: false
    });

  };

  const toastInfo = (message: string) => {
    addToast(message, 'is-info');
  };

  const toastSuccess = (message: string) => {
    addToast(message, 'is-success');
  };

  const toastError = (message: string) => {
    addToast(message, 'is-danger');
  };

  const value = {
    toastInfo,
    toastSuccess,
    toastError
  };

  return <ToastContext.Provider value={value}>{props.children}</ToastContext.Provider>;
};


export const useToast = () => {
  const currentLoggerContext = useContext(ToastContext);

  if (!currentLoggerContext) {
    throw new Error(
      "useLogger has to be used within <LoggerContext.Provider>"
    );
  }

  return currentLoggerContext;
};

// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
