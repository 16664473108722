import { createContext, useContext, useState, PropsWithChildren, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLogger } from "./useLogger";

// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
function parseJwt (token: string): JwtData {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

type JwtData = {
  sub: string,
  email: string,
  role: string
}

const emptyJwtData = { sub: '', email: '', role: ''}

const AuthContext = createContext<{token: string | null, jwtData: JwtData, login: (newToken:string) => void, logout: () => void }>(null!);

export const AuthProvider = (props: PropsWithChildren<{}>) => {
  const [token, setToken] = useState<string|null>(null);
  const [jwtData, setjwtData] = useState<JwtData>(emptyJwtData);
  const navigate = useNavigate();
  const logger = useLogger();

  // call this function when you want to authenticate the user
  const login = (newToken: string) => {
    logger.logInfo("AuthProvider.login: " + newToken);
    setToken(newToken);
    setjwtData(parseJwt(newToken));
    // navigate("/home", { replace: true });
  };

  // call this function to sign out logged in user
  const logout = () => {
    logger.logInfo("AuthProvider.logout");
    setToken(null);
    setjwtData(emptyJwtData);
    // navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      token,
      jwtData,
      login,
      logout
    }),
    [token]
  );

  useEffect(() => {
    if(token) {
      navigate("/", { replace: true });
    } else {
      navigate("/login", { replace: true });
    }
  }, [token]);

  return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};


export const useAuth = () => {
  const currentAuthContext = useContext(AuthContext);

  if (!currentAuthContext) {
    throw new Error(
      "useAuth has to be used within <AuthContext.Provider>"
    );
  }

  return currentAuthContext;
};

// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
// https://stackoverflow.com/questions/26402534/how-to-listen-state-changes-in-react-js
