import { Fragment } from "react";
import { Link, useLocation, useOutlet } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import classnames from "classnames";

export default function Npc() {
  const outlet = useOutlet();
  const pathname = useLocation().pathname;

  const menuItem =  (path: string, label: string) => {
    return (
      <li>
        <Link to={path} className={classnames(['', {"is-active": pathname.startsWith(path)}])}>{label}</Link>
      </li>
    );
  }

  return (
    <Fragment>
      <LoadingSpinner />
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-one-fifth-fullhd is-one-quarter">
            <aside className="menu">
              <p className="menu-label">
                Npcs
              </p>
              <ul className="menu-list">
                {menuItem("/npcs/list", "Npcs")}
              </ul>

              <p className="menu-label">
                Cleanup
              </p>
              <ul className="menu-list">
                {menuItem("/npcs/consolidate", "Consolidate")}
              </ul>
            </aside>
          </div>
          <div className="column">
            {outlet}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
