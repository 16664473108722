import { Fragment } from "react";
import { useLocation, useOutlet } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function Zone() {
  const outlet = useOutlet();
  const pathname = useLocation().pathname;

  return (
    <Fragment>
      <LoadingSpinner />
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-one-fifth-fullhd is-one-quarter" />
          <div className="column">
            {outlet}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
