import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { ConsolidationNpcType, useConsolidationsReducer } from './consolidationReducer';
import { races, genders, bodyTypes, classes } from '../../../data';
import classnames from 'classnames';

type Props = {
  npcType: string,
  consolidationNpcTypes: ConsolidationNpcType  [],
  onToggle: (npcType: string, consolidationNpcType: ConsolidationNpcType, selected: boolean) => void,
  onToggleAll: (npcType: string, selected: boolean) => void,
}

export function ConsolidateNpcTypeTable({npcType, consolidationNpcTypes, onToggle, onToggleAll}: Props) {
  const { toastInfo, toastError, toastSuccess } = useToast();
  const { token } = useAuth();
  const { mergeSuccess } = useConsolidationsReducer();
  const [allChecked, setAllChecked] = useState(false);
  const [isMerging, setIsMerging] = useState(false);

  useEffect(() =>{
    var areAllSelected = consolidationNpcTypes.every(x => x.selected);
    setAllChecked(areAllSelected);
  }, [consolidationNpcTypes]);


  const mergeNpcTypes = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    var mergeNpcTypeIds = consolidationNpcTypes.filter(x => x.selected).map(x => x.id);
    if(mergeNpcTypeIds.length === 0) {
      return;
    }

    toastInfo(`Starting merge for '${npcType}' with ids [${mergeNpcTypeIds.join(', ')}]`)
    setIsMerging(true);
    try {
      var result = await fetch(`/npctypes/consolidate`,
        {
          headers:{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: 'Bearer '+ token
          },
          method:'POST',
          body:JSON.stringify(mergeNpcTypeIds)
        });

      if(!result.ok) {
        throw new Error(`Merge failed with error code '${result.status}'`);
      }

      mergeSuccess(npcType, mergeNpcTypeIds);
      toastSuccess(`Merge completed for  '${npcType}' with ids [${mergeNpcTypeIds.join(', ')}]`);
    } catch(e){
      toastError(`Merge failed for  '${npcType}' with ids [${mergeNpcTypeIds.join(', ')}]`);
    }
    setIsMerging(false);
  };

  const toggleAll = (toggle: boolean) => {
    onToggleAll(npcType, toggle);
  }

  const toggleNpcType = (consolidationNpcType: ConsolidationNpcType, toggle: boolean) => {
    onToggle(npcType, consolidationNpcType, toggle);
  }

  const renderTableBody = (tableData: ConsolidationNpcType[]) => {
    return tableData.map(item => {
      return (
        <tr key={item.id}>
          <td><input type="checkbox" checked={item.selected ?? false} onChange={e => toggleNpcType(item, e.target.checked) } /></td>
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>{item.factionId}</td>
          <td>{genders[item.genderId]} [{item.genderId}]</td>
          <td>{item.lootTableId}</td>
          <td>{races[item.raceId]} [{item.raceId}]</td>
          <td>{classes[item.classId]} [{item.classId}]</td>
          <td>{bodyTypes[item.bodyType]} [{item.bodyType}]</td>
        </tr>
      );
    })
  }

  return (
    <div className="box is-relative">
      <div className='ribbon'>
        <p className="has-text-white is-4 is-capitalized has-text-weight-semibold">{npcType.replaceAll('_', ' ')}</p>
      </div>
      <article className="media">
        <div className="media-content">
          <div className="content">
            <table className="table is-striped">
              <thead>
                <tr>
                  <th><input type="checkbox" checked={allChecked} onChange={e => toggleAll(e.target.checked) }/></th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>FactionId</th>
                  <th>Gender</th>
                  <th>LootTableId</th>
                  <th>Race</th>
                  <th>Class</th>
                  <th>BodyType</th>
                </tr>
              </thead>
              <tbody>
                {renderTableBody(consolidationNpcTypes)}
              </tbody>
            </table>
          </div>
          <div className="buttons">
            <button className={classnames(["button", "is-primary", {"is-loading": isMerging}])} onClick={mergeNpcTypes}>Merge</button>
          </div>
        </div>
      </article>
    </div>
  );
}
