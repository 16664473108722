import { useLoading } from '../hooks/useLoading';

function LoadingSpinner() {
  const { isLoading } = useLoading();

  return (
    <>
      {isLoading ? <div className="site-loader-overlay is-flex"><div className="site-loader-wrapper is-flex flex-wrap is-justify-content-center is-align-items-center is-flex-grow-1"><span className="site-loader "></span></div></div> : <></> }

    </>
  );
}

export default LoadingSpinner;
