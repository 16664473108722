// https://medium.com/@mohsentaleb/elegantly-type-reacts-usereducer-and-context-api-with-discriminated-union-of-typescript-855ff475cafe

import { ActionFuncMap, assertUnreachable } from "../../../common/utils";
import { PropsWithChildren, createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { dispatcherWrapper } from "../../../common/reducerUtils";

enum Types {
  SetOldPassword = 'SET_OLD_PASSWORD',
  SetNewPassword = 'SET_NEW_PASSWORD',
  SetVerifyNewPassword = 'SET_VERIFY_NEW_PASSWORD',
}

type ProfileState = {
  oldPassword: string;
  newPassword: string;
  verifyNewPassword: string;
}

// https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm

const reducerActions = {
  setOldPassword: (value: string) => { return {type: Types.SetOldPassword  as typeof Types.SetOldPassword, payload: {password: value}} },
  setNewPassword: (value: string) => { return {type: Types.SetNewPassword as typeof Types.SetNewPassword, payload: {password: value}} },
  setVerifyNewPassword: (value: string) => { return {type: Types.SetVerifyNewPassword as typeof Types.SetVerifyNewPassword, payload: {password: value}} },
}
type ProfileActions = ActionFuncMap<typeof reducerActions>[keyof ActionFuncMap<typeof reducerActions>];

// https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
const profileReducer = (draft: ProfileState, action: ProfileActions) => {
  switch (action.type) {
    case Types.SetOldPassword:
      draft.oldPassword = action.payload.password;
      return draft;
    case Types.SetNewPassword:
      draft.newPassword = action.payload.password;
      return draft;
    case Types.SetVerifyNewPassword:
      draft.verifyNewPassword = action.payload.password;
      return draft;
  }

  return assertUnreachable(action);
};


// https://prateeksurana.me/blog/simplify-immutable-data-structures-in-usereducer-with-immer/
const dispacthActions = dispatcherWrapper<ProfileActions, typeof reducerActions>(reducerActions);
const ProfileContext = createContext<ReturnType<typeof dispacthActions> & { state: Readonly<ProfileState> }>(null!);

export const ProfileProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useImmerReducer<ProfileState, ProfileActions>(
    profileReducer,
    {
      oldPassword: "",
      newPassword: "",
      verifyNewPassword: ""
    }
  );

  const actions = dispacthActions(dispatch);
  const value = {...actions, state};

  return <ProfileContext.Provider value={value}>{props.children}</ProfileContext.Provider>;
};


export const useProfileReducer = () => {
  const currentContext = useContext(ProfileContext);

  if (!currentContext) {
    throw new Error(
      "useProfileReducer has to be used within <ProfileContext.Provider>"
    );
  }

  return currentContext;
};
