import { createContext, useContext, PropsWithChildren, useState, useMemo } from "react";
import { useLogger } from "./useLogger";

const LoadingContext = createContext<{startLoading: () => void, endLoading: () => void, isLoading: boolean}>(null!);

export const LoadingProvider = (props: PropsWithChildren<{}>) => {
  const [loadingCounter, setLoadingCounter] = useState(0);
  const logger = useLogger();

  const startLoading = () => {
    setLoadingCounter(count => count + 1);
  };

  const endLoading = () => {
    setLoadingCounter(count => count - 1);
  };

  const value = useMemo(
    () => ({
      startLoading,
      endLoading,
      isLoading: loadingCounter > 0
    }),
    [loadingCounter]
  );

  return <LoadingContext.Provider value={value}>{props.children}</LoadingContext.Provider>;
};


export const useLoading = () => {
  const currentLoggerContext = useContext(LoadingContext);

  if (!currentLoggerContext) {
    throw new Error(
      "useLogger has to be used within <LoggerContext.Provider>"
    );
  }

  return currentLoggerContext;
};

// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
