import { Fragment } from 'react';
import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider, Outlet } from 'react-router-dom';
import { Login, Home, NotFound, Npcs, Consolidation, Profile, NpcsList, UserList, UserAdd, Users, UserUdpate, Zone, ZoneList } from './pages';
import AuthorizedRoute from './components/AuthorizedRoute';
import { AuthProvider } from './hooks/useAuth';
import { LoggerProvider } from './hooks/useLogger';
import { LoadingProvider } from './hooks/useLoading';
import { ToastProvider } from './hooks/useToast';

function App() {
  return (
    <Fragment>
      <LoggerProvider>
        <ToastProvider>
          <LoadingProvider>
            <RouterProvider router={router} />
          </LoadingProvider>
        </ToastProvider>
      </LoggerProvider>
    </Fragment>
  );
}


const AuthLayout = () => (
  <AuthProvider>
    <Outlet />
  </AuthProvider>
);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<AuthLayout />} >
        <Route path="/" element={<AuthorizedRoute authenticationPath='login' />}>
          <Route index element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="npcs" element={<Npcs />} >
            <Route path="list" element={<NpcsList />} />
            <Route path="consolidate" element={<Consolidation />} />
          </Route>
          <Route path="zone" element={<Zone />} >
            <Route path="list" element={<ZoneList />} />
          </Route>
          <Route path="user" element={<Users />}>
            <Route path="list" element={<UserList />} />
            <Route path="add" element={<UserAdd />} />
            <Route path=":userId" element={<UserUdpate />} />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </>
  )
);

export default App;
