import { Dispatch } from "react";

// https://stackoverflow.com/questions/51851677/how-to-get-argument-types-from-function-in-typescript
// https://stackoverflow.com/questions/45020874/typescript-wrapping-function-with-generic-type
type InferArgs<T> = T extends (...t: [...infer Arg]) => any ? Arg : never;

const dispatchFuncWrapper = <T, TFunc extends (...args: any[]) => T>(func: TFunc, dispatch: Dispatch<T>)
    : (...args: InferArgs<TFunc>) => void => (...args: InferArgs<TFunc>) => dispatch(func(...args));

export const dispatcherWrapper = <T, M extends { [index: string]: (...args: any[]) => T }>(actions: M) => (dispatch: Dispatch<T>): { [P in keyof M]: (...args: InferArgs<M[P]>) => void } => {
  const remapped: any = {};
  Object.keys(actions).forEach(k => {
      remapped[k] = dispatchFuncWrapper(actions[k], dispatch);
  });

  return remapped;
};
