// https://medium.com/@mohsentaleb/elegantly-type-reacts-usereducer-and-context-api-with-discriminated-union-of-typescript-855ff475cafe

import { ActionFuncMap, assertUnreachable } from "../../../common/utils";
import { PropsWithChildren, createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { dispatcherWrapper } from "../../../common/reducerUtils";

export type User = {
  id: number,
  name: string,
  email: string,
  oldPassword: string,
  newPassword: string,
  role: number,
  isActive: boolean
}

enum Types {
  SetUsers = 'SET_USERS',
  UpdateUser = 'UDPATE_USER',
  RemoveUser = 'REMOVE_USER',
}

type State = {
  users: User[];
}

const reducerActions = {
  setUsers: (value: User[]) => { return {type: Types.SetUsers  as typeof Types.SetUsers, payload: value} },
  updateUser: (value: User) => { return {type: Types.UpdateUser  as typeof Types.UpdateUser, payload: value} },
  removeUser: (value: User) => { return {type: Types.RemoveUser  as typeof Types.RemoveUser, payload: value} },
}
type Actions = ActionFuncMap<typeof reducerActions>[keyof ActionFuncMap<typeof reducerActions>];

// https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
const reducer = (draft: State, action: Actions) => {
  switch (action.type) {
    case Types.SetUsers:
      draft.users = action.payload;
      return draft;
    case Types.UpdateUser:
      draft.users = draft.users.map(user => {
        if(user.id === action.payload.id)
        {
          return action.payload;
        }

        return user;
      });
      return draft;
    case Types.RemoveUser:
      draft.users = draft.users.filter(user => user.id !== action.payload.id);
      return draft;
  }

  return assertUnreachable(action);
};


const dispacthActions = dispatcherWrapper<Actions, typeof reducerActions>(reducerActions);
const UsersContext = createContext<ReturnType<typeof dispacthActions> & { state: Readonly<State> }>(null!);

export const UsersProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useImmerReducer<State, Actions>(
    reducer,
    {
      users: [],
    }
  );

  const actions = dispacthActions(dispatch);
  const value = {...actions, state};

  return <UsersContext.Provider value={value}>{props.children}</UsersContext.Provider>;
};


export const useUsersReducer = () => {
  const currentContext = useContext(UsersContext);

  if (!currentContext) {
    throw new Error(
      "useUsersReducer has to be used within <UsersContext.Provider>"
    );
  }

  return currentContext;
};
