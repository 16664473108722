import { createContext, useContext, PropsWithChildren } from "react";

const LoggerContext = createContext<{logTrace: (data:string) => void, logInfo: (data:string) => void, logError: (data:string) => void}>(null!);

export const LoggerProvider = (props: PropsWithChildren<{}>) => {

  const logTrace = (message: string) => {
    console.trace(message);
  };

  const logInfo = (message: string) => {
    console.info(message);
  };

  const logError = (message: string) => {
    console.error(message);
  };

  const value = {
    logTrace,
    logInfo,
    logError
  };

  return <LoggerContext.Provider value={value}>{props.children}</LoggerContext.Provider>;
};


export const useLogger = () => {
  const currentLoggerContext = useContext(LoggerContext);

  if (!currentLoggerContext) {
    throw new Error(
      "useLogger has to be used within <LoggerContext.Provider>"
    );
  }

  return currentLoggerContext;
};

// https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/
