import React from 'react';

function HomePage() {
  return (
    <>
      <section className="section">
        <nav className="level">
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Characters</p>
              <p className="title">3,456</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Logged in</p>
              <p className="title has-text-info">123</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Looted items</p>
              <p className="title has-text-success">456K</p>
            </div>
          </div>
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Deaths</p>
              <p className="title has-text-danger">789</p>
            </div>
          </div>
        </nav>
      </section>
      <section className="section">
        <div className="container">
          <h1 className="title">
            Home of the Firiona Vie Project database editor.
          </h1>
          <p className="subtitle">
            Based on the <strong>PEQ</strong> database!
          </p>
        </div>
      </section>
    </>
  );
}

export default HomePage;
