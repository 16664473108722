import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { races, genders, bodyTypes, classes } from '../../../data';
import classnames from 'classnames';
import { NpcType, useNpcsReducer } from './npcsReducer';

type Props = {
  zoneName: string,
  npcTypes: NpcType  [],
  onToggle: (npcType: NpcType, selected: boolean) => void,
  onToggleAll: (selected: boolean) => void,
}

export function NpcTypeTable({zoneName, npcTypes, onToggle, onToggleAll}: Props) {
  const { toastInfo, toastError, toastSuccess } = useToast();
  const { token } = useAuth();
  const { deleteSuccess } = useNpcsReducer();
  const [allChecked, setAllChecked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() =>{
    var areAllSelected = npcTypes.every(x => x.selected);
    setAllChecked(areAllSelected);
  }, [npcTypes]);


  const deleteNpcTypes = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    var deleteNpcTypes = npcTypes.filter(x => x.selected);
    if(deleteNpcTypes.length === 0) {
      return;
    }

    toastInfo(`Starting delete of npctypes`)
    setIsDeleting(true);
    try {
      for (const delete_npctype of deleteNpcTypes) {
        var result = await fetch(`/npctypes/${delete_npctype.id}`,
          {
            headers:{
              method: "DELETE", // *GET, POST, PUT, DELETE, etc.
              "Accept": "application/json",
              "Content-Type": "application/json",
              Authentication: 'Bearer '+ token
            },
            method:'DELETE',
          });

        if(!result.ok) {
          throw new Error(`Delete failed with error code '${result.status}'`);
        }

        deleteSuccess(delete_npctype);
      }

      toastSuccess(`Delete completed`);
    } catch(e){
      toastError(`Delete failed`);
    }
    setIsDeleting(false);
  };

  const toggleAll = (toggle: boolean) => {
    onToggleAll(toggle);
  }

  const toggleNpcType = (npcType: NpcType, toggle: boolean) => {
    onToggle(npcType, toggle);
  }

  const renderTableBody = (tableData: NpcType[]) => {
    return tableData.map(item => {
      return (
        <tr key={item.id}>
          <td><input type="checkbox" checked={item.selected ?? false} onChange={e => toggleNpcType(item, e.target.checked) } /></td>
          <td>{item.id}</td>
          <td>{item.name}</td>
          <td>{item.factionId}</td>
          <td>{genders[item.genderId]} [{item.genderId}]</td>
          <td>{item.lootTableId}</td>
          <td>{races[item.raceId]} [{item.raceId}]</td>
          <td>{classes[item.classId]} [{item.classId}]</td>
          <td>{bodyTypes[item.bodyType]} [{item.bodyType}]</td>
        </tr>
      );
    })
  }

  return (
    <div className="box is-relative">
      <div className='ribbon'>
        <p className="has-text-white is-4 is-capitalized has-text-weight-semibold">{zoneName}</p>
      </div>
      <article className="media">
        <div className="media-content">
          <div className="content">
            <table className="table is-striped">
              <thead>
                <tr>
                  <th><input type="checkbox" checked={allChecked} onChange={e => toggleAll(e.target.checked) }/></th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>FactionId</th>
                  <th>Gender</th>
                  <th>LootTableId</th>
                  <th>Race</th>
                  <th>Class</th>
                  <th>BodyType</th>
                </tr>
              </thead>
              <tbody>
                {renderTableBody(npcTypes)}
              </tbody>
            </table>
          </div>
          <div className="buttons">
            <button className={classnames(["button", "is-primary", {"is-loading": isDeleting}])} onClick={deleteNpcTypes}>Delete</button>
          </div>
        </div>
      </article>
    </div>
  );
}
