import classNames from "classnames";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUndo, faCaretDown, faTimes } from '@fortawesome/free-solid-svg-icons';

function ComboBox() {

  const onClear = (evt: any) => {
  }

  const onSearch = (evt: any) => {
  }

  const onUndo = (evt: any) => {
  }

  const canClear = true;
  const canUndo = true;
  const canToggleResultView = false;

  const inputPaddingRightStyle = () => {
    let rightStylePadding = 0;
    if(canClear || canUndo) {
      rightStylePadding += 2;
    }

    // if((state.isWorking || props.isDisabled === true || props.smartBoOptions === undefined) === false) {
    //   rightStylePadding += 2;
    // }

    if(rightStylePadding > 0) {
      return `${rightStylePadding + 2.3}rem`;
    }

    return undefined;
  }

  const calculatePosition = (...args: Array<boolean>) => {
    let positionOffset = 0;
    for (let index = 0; index < args.length; index++) {
      if (args[index] === true) {
        positionOffset += 2;
      }
    }
    return `${positionOffset}em`;
  }

  const renderClearButton = () => {
    if(canClear) {
      const positionRight = calculatePosition(true);
      return (
        <button type='button'
                tabIndex={-1}
                className='control icon is-small is-right has-borders-none has-bg-transparent'
                style={{pointerEvents: 'inherit', cursor: 'pointer', right: positionRight}}
                onClick={onClear}>
                <FontAwesomeIcon icon={faTimes} />
        </button>
      )
    }

    return <></>;
  }

  const renderSearchButton = () => {
    if(false) {
      return <></>;
    }

    return (
      <button type='button'
              tabIndex={-1}
              className='control icon is-small is-right has-borders-none has-bg-transparent'
              style={{pointerEvents: 'inherit', cursor: 'pointer'}}
              onClick={onSearch}>
                <FontAwesomeIcon icon={faCaretDown} />
      </button>
    )
  }

  const renderUndoButton = () => {
    if(canUndo) {
      const positionRight = calculatePosition(canClear, canUndo);
      return (
        <button type='button'
                tabIndex={-1}
                className='control icon is-small is-right has-borders-none has-bg-transparent'
                style={{pointerEvents: 'inherit', cursor: 'pointer', right: positionRight}}
                onClick={onUndo} >
                  <FontAwesomeIcon icon={faUndo} />
        </button>
      )
    }

    return <></>;
  }

  return (
    <>
      <div className={classNames('control', 'is-small', {'has-icons-right': (canClear || canUndo)}, {'is-loading': false})}>
        <input  type="text"
                placeholder="Test"
                className="input"
                style={{paddingRight: inputPaddingRightStyle()}} />
        { renderUndoButton() }
        { renderClearButton() }
        { renderSearchButton() }
      </div>
    </>
  );
}

export default ComboBox;
