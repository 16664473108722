import { useEffect } from "react";
import { useLeafletContext } from '@react-leaflet/core'
import "leaflet-polylinedecorator";
import L, { LatLng, latLngBounds, LatLngBoundsLiteral, layerGroup } from "leaflet";
import { useMap } from "react-leaflet";
import { Zone } from "../../domain/zone";
import { useLogger } from "../../hooks/useLogger";

const dashPattern = {
  offset: 0,
  endOffset: 0,
  repeat: 10,
  symbol: L.Symbol.dash({
    pixelSize: 2,
    pathOptions: {
      color: 'hsl(0, 0%, 71%)',
      weight: 1
    }
  })
}

export const  GridLines = (props: {zoom: number, boundsLiteral: LatLngBoundsLiteral, showgrid?: boolean, zone: Zone}) => {
  const context = useLeafletContext()
  const map = useMap();

  useEffect(() => {
    const container = context.layerContainer || context.map
    const layerGroup = new L.LayerGroup();
    if(props.zone.id > 0 && props.showgrid) {
      const bounds = latLngBounds(props.boundsLiteral)
      const currentBounds = map.getBounds()
      const width = currentBounds.getEast()-currentBounds.getWest();
      const height = currentBounds.getNorth()-currentBounds.getSouth();
      const w_step = width / 5 * height/width;
      const h_step = height / 5;
      for (let lat = bounds.getWest() - w_step; lat < bounds.getEast() + w_step; lat += w_step){
        for (let lng = bounds.getSouth() - h_step; lng < bounds.getNorth() + h_step; lng += h_step) {
          const label = `${Math.round(lng)}, ${Math.round(lat)*-1}`
          const size = 5*(label.length-1);
          const meridians = L.polylineDecorator(new L.Polyline([[lng, lat], [lng + h_step, lat]]), {
              patterns : [
                dashPattern,
                {
                  offset: 0,
                  endOffset: 0,
                  repeat: 0,
                  symbol: L.Symbol.marker({
                    rotate: true,
                    markerOptions: {
                      zIndexOffset: 200,
                      title: label,
                      icon: L.divIcon({
                          className: "",
                          html: "<span style='font-size: 8px; background: white'>" + label + "</span>",
                          bgPos: [0, 0],
                          iconAnchor: [size/2, 9], // center it using half the label width
                          iconSize: [size, 20]
                      })
                    }
                  })
                }
              ]
          });

          const parallels = L.polylineDecorator(new L.Polyline([[lng, lat], [lng, lat + w_step]]), {
              patterns : [dashPattern]
          });

          layerGroup.addLayer(meridians);
          layerGroup.addLayer(parallels);
        }
      }
    }

    container.addLayer(layerGroup)

    return () => {
      container.removeLayer(layerGroup)
    }
  }, [props.zoom, props.showgrid, props.zone, context.layerContainer, context.map, map, props.boundsLiteral])

  return null
}
