import { useEffect } from 'react';
import { useLogger } from '../../../hooks/useLogger';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { useLoading } from '../../../hooks/useLoading';
import { User, UsersProvider, useUsersReducer } from './userReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { produce } from 'immer';

function AddUserForm() {
  const logger = useLogger();
  const { toastError, toastSuccess } = useToast();
  const { startLoading, endLoading } = useLoading();
  const { token, jwtData } = useAuth();
  const { state, setUsers, updateUser, removeUser } = useUsersReducer();

  useEffect(() => {
    const abortController = new AbortController();
    const fetchUsers = async () => {
      startLoading();
      try {
        var result = await fetch('/users',
          {
            headers:{
              method: "GET", // *GET, POST, PUT, DELETE, etc.
              "Accept": "application/json",
              "Content-Type": "application/json",
              Authentication: `Bearer ${token}`
            },
          });

        if(!result.ok) {
          var errorMsg = result.text();
          toastError(`Failed to fetch users: ${errorMsg}`);
          throw new Error(`Request failed with error code '${result.status}'`);
        }

        var users: User[] = await result.json();
        setUsers(users);
        toastSuccess("Fetched users.");
      } catch (e){
        // https://stackoverflow.com/questions/54649465/how-to-do-try-catch-and-finally-statements-in-typescript
        // https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
        logger.logError((e as Error).message);
      }
      endLoading();
    };

    // call the function
    fetchUsers();
    return () => abortController.abort();
  }, [token]);

  const deleteUser = async (event: React.MouseEvent<HTMLElement>, user: User) => {
    event.preventDefault();
    startLoading();
    try {
      var result = await fetch(`/users/${user.id}`,
        {
          headers:{
            method: "DELETE", // *GET, POST, PUT, DELETE, etc.
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: `Bearer ${token}`
          },
          method:'DELETE',
        });

      if(!result.ok) {
        var errorMsg = result.text();
        toastError(`Failed to delete user: ${errorMsg}`);
        throw new Error(`Request failed with error code '${result.status}'`);
      }

      removeUser(user);
      toastSuccess("Deleted user.");
    } catch (e){
      // https://stackoverflow.com/questions/54649465/how-to-do-try-catch-and-finally-statements-in-typescript
      // https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
      logger.logError((e as Error).message);
    }
    endLoading();
  };


  const renderTableBody = (tableData: User[]) => {
    return tableData.map(item => {
      return (
        <tr key={item.id}>
          <td style={{verticalAlign: 'middle'}}>{item.id}</td>
          <td style={{verticalAlign: 'middle'}}>{item.name}</td>
          <td style={{verticalAlign: 'middle'}}>{item.email}</td>
          <td style={{verticalAlign: 'middle'}}>{item.role}</td>
          <td>
            <div className="is-flex">
              <input id="isActive" type="checkbox" name="isActive" className={classNames(["switch is-small", {"is-success": item.isActive}])} checked={item.isActive} onChange={event => updateUser(produce(item, user => {user.isActive = event.target.checked; return user}))} disabled={jwtData.role !== "Administrator"} />
              <label htmlFor="isActive" />
            </div>
          </td>
          <td>
            <div className="buttons is-justify-content-flex-end">
              <Link to={`/user/${item.id}`} className='button is-primary is-small mb-0' data-tooltip="Edit User">
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faPencil} />
                </span>
              </Link>
              <button className="button is-danger is-small mb-0" data-tooltip="Delete User" onClick={e => deleteUser(e, item)} disabled={jwtData.role !== "Administrator"}>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faTrashCan} />
                </span>
              </button>
            </div>
          </td>
        </tr>
      );
    })
  }

  return (
    <div className="container is-fluid">
      <div className="container mt-4">
        <div className="box is-relative">
          <article className="media">
            <div className="media-content">
              <div className="content">
                <table className="table is-striped">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>Enabled</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {renderTableBody(state.users)}
                  </tbody>
                </table>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

function AddUser() {
  return (
    <UsersProvider>
      <AddUserForm />
    </UsersProvider>
  );
}

export default AddUser;
