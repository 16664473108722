import { Fragment } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useLogger } from "../hooks/useLogger";
import NavBar from "./NavBar";

export type ProtectedRouteProps = {
  authenticationPath: string;
};

export default function AuthorizedRoute({authenticationPath}: ProtectedRouteProps) {
  const { token } = useAuth();
  const outlet = useOutlet();
  const logger = useLogger();

  if(!token) {
    logger.logInfo("AuthorizedRoute: " + token);
    return <Navigate to={{ pathname: authenticationPath }} />
  }

  return (
    <Fragment>
      <NavBar />
      {outlet}
    </Fragment>
  );
};


// https://stackoverflow.com/questions/47747754/how-to-rewrite-the-protected-private-route-using-typescript-and-react-router-4
// https://stackoverflow.com/questions/69982197/page-layout-broken-in-react-router-v6
