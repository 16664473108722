import { faEnvelope, faKey, faUser, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLogger } from '../../../hooks/useLogger';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { useLoading } from '../../../hooks/useLoading';
import { AddProvider, useAddReducer } from './addReducer';

function ProfileForm() {
  const logger = useLogger();
  const { toastError, toastSuccess } = useToast();
  const { startLoading, endLoading } = useLoading();
  const { token } = useAuth();
  const { setUserName, setEmail, setRole, setNewPassword, setVerifyNewPassword, state } = useAddReducer();

  const updatePassword = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    startLoading();
    try {
      var result = await fetch('/users',
        {
          headers:{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: `Bearer ${token}`
          },
          method:'POST',
          body:JSON.stringify({name: state.userName, email: state.email, role: state.role, oldPassword: state.newPassword, newPassword: state.verifyNewPassword})
        });

      if(!result.ok) {
        var errorMsg = result.text();
        toastError(`Failed to add new user: ${errorMsg}`);
        throw new Error(`Request failed with error code '${result.status}'`);
      }

      toastSuccess("Added user.");
    } catch (e){
      // https://stackoverflow.com/questions/54649465/how-to-do-try-catch-and-finally-statements-in-typescript
      // https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
      logger.logError((e as Error).message);
    }
    endLoading();
  };

  const canSubmit = () => state.userName !== "" && state.email !== "" && state.newPassword !== "" && state.newPassword === state.verifyNewPassword;

  return (
    <div className="container is-fluid">
      <div className="notification is-primary is-light">
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <h1 className="title is-4">
              Add User
              </h1>
            </div>
          </div>
        </nav>
      </div>
      <div className="container mt-5">
        <form>
          <div className="field">
            <label className="label">User name</label>
            <p className="control has-icons-left">
              <input className="input" placeholder="username" id="username" type="text" value={state.userName} onChange={(event) => { setUserName(event.target.value); }} />
              <span className="icon is-left is-small">
                <FontAwesomeIcon icon={faUser} />
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label">Email</label>
            <p className="control has-icons-left">
              <input className="input" placeholder="email" id="email" type="email" value={state.email} onChange={(event) => { setEmail(event.target.value); }} />
              <span className="icon is-left is-small">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label">Role</label>
            <p className="control has-icons-left">
              <div className="select">
                <select value={state.role} onChange={event => setRole(Number(event.target.value))}>
                  <option value="0">Un-Registrered</option>
                  <option value="1">Registrered</option>
                  <option value="2">Server Operator</option>
                  <option value="3">Administrator</option>
                </select>
              </div>
              <span className="icon is-left is-small">
                <FontAwesomeIcon icon={faUserLock} />
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label">New password</label>
            <p className="control has-icons-left">
              <input className="input" placeholder="password" id="newpassword" type="password" value={state.newPassword} onChange={(event) => { setNewPassword(event.target.value); }} />
              <span className="icon is-left is-small">
                <FontAwesomeIcon icon={faKey} />
              </span>
            </p>
          </div>
          <div className="field">
            <label className="label">Verify password</label>
            <p className="control has-icons-left">
              <input className="input" placeholder="verify password" id="verifypassword" type="password" value={state.verifyNewPassword} onChange={(event) => { setVerifyNewPassword(event.target.value); }} />
              <span className="icon is-left is-small">
                <FontAwesomeIcon icon={faKey} />
              </span>
            </p>
          </div>
          <button className="button is-primary" disabled={!canSubmit()} onClick={(updatePassword)}>Submit</button>
        </form>
      </div>
    </div>
  );
}

function Profile() {
  return (
    <AddProvider>
      <ProfileForm />
    </AddProvider>
  );
}

export default Profile;
