import { Fragment, useState } from "react";
import { CircleMarker, LayerGroup, Polyline, Popup, Tooltip } from "react-leaflet";
import { LatLng, LatLngTuple } from "leaflet";
import { Polygon, ZonePolygons } from "../../domain/map";
import { SpawnEntryNpcType, ZoneSpawnEntry, isEnabled } from "../../domain/zone";
import { expansions } from "../../data";

type SpawnPointProps = {
  spawnpoint: ZoneSpawnEntry,
  isFocused?: (spawnpoint: ZoneSpawnEntry) => boolean,
  zonePolygons: ZonePolygons,
  showgrid?: boolean
};

const spawnPointColor = (spawnpoint: ZoneSpawnEntry) => {
  if(isEnabled(spawnpoint)) {
    return "hsl(141, 71%, 48%)";
  }

  return "hsl(0, 0%, 71%)";
}

const renderNpc = (npc: SpawnEntryNpcType) => {
  var cleanName = `${npc.npcName.replaceAll("_", " ").trim()}`
  return (
    <tr key={npc.npcId}>
      <td style={{padding: "0 2px"}}>{cleanName}</td>
      <td style={{padding: "0 2px"}}>{npc.spawnChance}%</td>
      <td style={{padding: "0 2px"}}>{expansions[npc.minExpansion]}</td>
      <td style={{padding: "0 2px"}}>{expansions[npc.maxExpansion]}</td>
      <td style={{padding: "0 2px"}}>{npc.contentFlags}</td>
    </tr>
  );
}

const getExpansions = (zoneSpawnEntry: ZoneSpawnEntry) => {
  if(zoneSpawnEntry.minExpansion == zoneSpawnEntry.maxExpansion) {
    return <Fragment>{expansions[zoneSpawnEntry.minExpansion]}</Fragment>
  }
  return <Fragment>{expansions[zoneSpawnEntry.minExpansion]} -&#62; {expansions[zoneSpawnEntry.maxExpansion]}</Fragment>
}


const renderPolyLine = (showGrid: boolean, highlight: boolean, polygon: Polygon, eqLocToMapCoordinates: (x: number, y: number) => LatLng) => {
  if(!showGrid && !highlight) {
    return <Fragment />
  }

  var data = polygon.lines.map(line => {
    return {
      coordinates: [eqLocToMapCoordinates(line.p1.y, line.p1.x), eqLocToMapCoordinates(line.p2.y, line.p2.x)],
      color: polygon.hexRGB
    }
  });

  var color = highlight ? 'hsl(171, 100%, 41%)' : 'hsl(0, 0%, 86%)';
  return data.map((item, index) => {
    return (
      <Polyline key={index} pathOptions={{ color: color }} positions={item.coordinates} weight={1} dashArray='2, 2' dashOffset='0' />
    );
  })
}

export const SpawnPoint = ({spawnpoint, isFocused, zonePolygons, showgrid}: SpawnPointProps) => {
    var [highlightGrid, setHighlightGrid] = useState<boolean>(false);

    var spawnIsFocused = isFocused ? isFocused(spawnpoint) : false;
    // var cleanName = `${spawnPoint.npcName.replaceAll("_", " ").trim()}`
    var markerColor = spawnPointColor(spawnpoint);
    var cleanName = `${spawnpoint.spawnGroupName.trim()}`
    var key = `${spawnpoint.id}-${isFocused}`
    var radius = spawnIsFocused ? 5 : 2;
    var fillOpacity = spawnIsFocused ? 0.1 : 1;

    const toggleHighlightGrid = () => {
      setHighlightGrid(!highlightGrid)
    };

    return (
      <LayerGroup key={key}>
        {renderPolyLine(showgrid || false, highlightGrid, spawnpoint.pathGrid, zonePolygons.converters.eqLocToMapCoordinates)}

        <CircleMarker key={key} center={zonePolygons.converters.eqLocToMapCoordinates(spawnpoint.y, spawnpoint.x)} radius={radius} weight={2} stroke={true} color={markerColor} fillOpacity={fillOpacity}>
          <Popup>
            <article className="message is-info is-small">
              <div className="message-header">
                {cleanName}
              </div>
              <div className="message-body has-background-white has-text-grey-dark is-size-7">
                <div><span className='has-text-grey'>Id: </span>{spawnpoint.id}</div>
                <div><span className='has-text-grey'>Spawn Point (x,y,z): </span>{spawnpoint.x}, {spawnpoint.y}, {spawnpoint.z}</div>
                <div><span className='has-text-grey'>Enabled: </span>{spawnpoint.enabled ? "on" : "off"}</div>
                <div><span className='has-text-grey'>Expansions: </span>{getExpansions(spawnpoint)}</div>
                <div><span className='has-text-grey'>Content flags: </span>{spawnpoint.contentFlags}</div>
                <div className="field">
                  <input id="switchHighlightGrid" type="checkbox" name="switchHighlightGrid" className="switch is-small" checked={highlightGrid} onChange={toggleHighlightGrid} disabled={spawnpoint.pathGrid.lines.length === 0} />
                  <label htmlFor="switchHighlightGrid" className="is-small">Highlight grid</label>
                </div>
                <div>Npcs</div>
                <table>
                  <tbody>
                    {spawnpoint.npcTypes.map(renderNpc)}
                  </tbody>
                </table>
              </div>
            </article>
          </Popup>
          <Tooltip permanent={false}>{cleanName}</Tooltip>
        </CircleMarker>
      </LayerGroup>
    )
}
