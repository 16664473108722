import { LatLngExpression } from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

type ChangeMapViewProps = {
  center: LatLngExpression;
  zoom: number;
};

export const ChangeMapView = ({center, zoom}: ChangeMapViewProps) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom]);

  return null;
}
