import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faKey } from '@fortawesome/free-solid-svg-icons'

import logo from '../../assets/images/fv.jpg';
import { useAuth } from '../../hooks/useAuth';
import { useLogger } from '../../hooks/useLogger';

function LoginPage() {
  const logger = useLogger();
  const { login } = useAuth();
  const [ userName, setUserName ] = useState<string>('');
  const [ password, setPassWord ] = useState<string>('');
  const [ error, setError ] = useState<string>('');

  const fetchToken = async (username: string, password: string) => {
    if(userName === null || userName.trim().length === 0) {
      logger.logError("Missing username.");
      return;
    }

    if(password === null || password.trim().length === 0) {
      logger.logError("Missing password.");
      return;
    }

    setError("");
    try {
      var result = await fetch('/authorization/token',
        {
          headers:{
              "Accept": "application/json",
              "Content-Type": "application/json"
          },
          method:'POST',
          body:JSON.stringify({UserName: username, Password: password})
        });

      if(!result.ok) {
        throw new Error(`Request failed with error code '${result.status}'`);
      }

      var token = await result.text();
      login(token.replaceAll("\"", ""));
    } catch(e){
      console.log(e);
      setError("Login failed.\n")
    }
  };

  const loginUser = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    await fetchToken(userName, password);
  }

  const loginGuest = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    await fetchToken("guest", "guest");
  }

  const renderNotification = () => {
    if(error.length === 0) {
      return <></>
    }

    return (
      <div className="notification is-danger">
        {error}
      </div>
    );
  };

  return (
    <section className="section section-login">
      <div className="container has-text-centered">
        <div className="column is-4 is-offset-4">
          <h3 className="title has-text-grey is-3">The Firiona Vie Project</h3>
          <p className="subtitle has-text-grey has-text-weight-semibold">[Kunark]</p>
          <p className="has-text-grey is-family-monospace">Database Editor</p>
          <div className="box login-box">
            <figure className="image avatar">
              <img
                width="170"
                aria-hidden
                id="login-img"
                alt="based-ghost-logo"
                src={logo}
              />
            </figure>
            <form autoComplete="off">
              <div className="field">
                <p className="control has-icons-left">
                  <input className="input" placeholder="your@email.com" id="userName" type="text" value={userName ?? ""} onChange={(event) => { setUserName(event.target.value); }}/>
                  <span className="icon is-left is-small">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                </p>
              </div>
              <div className="field">
                <p className="control has-icons-left">
                  <input className="input" placeholder="password" id="password" type="password" value={password ?? ""} onChange={(event) => { setPassWord(event.target.value); }} />
                  <span className="icon is-left is-small">
                    <FontAwesomeIcon icon={faKey} />
                  </span>
                </p>
              </div>
              {renderNotification()}
              <button className="button is-fullwidth is-primary" disabled={userName == null || password == null} onClick={(loginUser)} >Login</button>
              <button className="button is-info is-inverted mt-3 is-small" disabled={false} onClick={(loginGuest)} >Continue as guest</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginPage;
