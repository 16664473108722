import { useEffect } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useLoading } from '../../../hooks/useLoading';
import { useToast } from '../../../hooks/useToast';
import { NpcsProvider, Zone, useNpcsReducer } from './npcsReducer';
import { NpcTypeTable } from './npcTypeTable';
import ComboBox from '../../../components/forms/combobox';

function NpcList() {
  const { toastInfo, toastError } = useToast();
  const { startLoading, endLoading } = useLoading();
  const { token } = useAuth();
  const { state, setZones, setSelectedZone, setNpcs, toggle, toggleAll } = useNpcsReducer();

  useEffect(() => {
    const abortController = new AbortController();
    // declare the async data fetching function
    const fetchData = async () => {
      try {
        const response = await fetch('/zones', {
          headers: {
            withCredentials: "true",
            crossorigin: "true",
            mode: 'no-cors',
            credentials: "include", // include, *same-origin, omit
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: 'Bearer '+ token
          }
        });
        const resultZones = await response.json();
        setZones([{ id: -1, shortName: "Select zone", longName: "Select zone", zoneNumber: -1 }].concat(resultZones));
      } catch (error: any) {
        // ℹ️: The error name is "CanceledError" for Axios.
        if (error.name !== "AbortError") {
          console.log(error);
        }
      }
    }
    // call the function
    fetchData();
    return () => abortController.abort();
  }, [token]);

  useEffect(() => {
    const abortController = new AbortController();
    // declare the async data fetching function
    const fetchData = async () => {
      try {
        if (state.selectedZone.id <= 0 ) {
          setNpcs([]);
          return;
        }

        const response = await fetch(`/zones/${state.selectedZone.id}/npctypes`, {
          headers: {
            withCredentials: "true",
            crossorigin: "true",
            mode: 'no-cors',
            credentials: "include", // include, *same-origin, omit
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: 'Bearer '+ token
          }
        });
        const result = await response.json();
        setNpcs(result);

      } catch (error: any) {
        // ℹ️: The error name is "CanceledError" for Axios.
        if (error.name !== "AbortError") {
          console.log(error);
        }
      }
    }

    // call the function
    fetchData();
    return () => abortController.abort();
  }, [state.selectedZone]);

  const selectZone = (event: any) => {
    var selectedZone = state.zoneData.find(zone => zone.id === Number(event.target.value))
    setSelectedZone(selectedZone);
  };

  function zoneText(zone: Zone) {
    if (zone.zoneNumber > 0) {
      return `${zone.longName} - ${zone.zoneNumber}`;
    }

    return `${zone.longName}`;
  }

  return (
    <>
    <div className="container is-fluid">
      <div className="notification">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="field">
                  <div className={`select ${state.zoneData.length === 1 && 'is-loading'}`}>
                    <select value={state.selectedZone.id} onChange={selectZone}>
                      {state.zoneData.map((zone: Zone, index: number) => <option key={index} value={zone.id}>{zoneText(zone)}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className="level-item">
                <div className="field">
                  <div className="select">
                    <select>
                      <option>Select Npc</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="level-right">
              <div className="level-item">
                <div className="field has-addons">
                  <p className="control">
                    <input className="input" type="text" placeholder="ID" />
                  </p>
                  <p className="control">
                    <button className="button">
                      Search
                    </button>
                  </p>
                </div>
              </div>
              <div className="level-item">
                <div className="field has-addons">
                  <p className="control">
                    <input className="input" type="text" placeholder="NPC Name" />
                  </p>
                  <p className="control">
                    <button className="button">
                      Search
                    </button>
                  </p>
                </div>
              </div>
              <div className="level-item">
                <div className="field">
                  <p className="field-body">
                    <ComboBox />
                  </p>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="container mt-4">
          <NpcTypeTable key={state.selectedZone.id} zoneName={state.selectedZone.longName} npcTypes={state.npcs} onToggle={toggle} onToggleAll={toggleAll} />
        </div>
      </div>
    </>
  );
};



function Npcs() {
  return (
    <NpcsProvider>
      <NpcList />
    </NpcsProvider>
  );
}

export default Npcs;
