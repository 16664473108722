import { ChangeEvent, useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/useAuth';
import { useLoading } from '../../../hooks/useLoading';
import { useToast } from '../../../hooks/useToast';
import { ZoneProvider, useZoneReducer, SpawnGroup, dummyZone, DefaultZonePolygons, getZoneMapPolygons } from './zoneRedcurer';
import { Zone, ZoneSpawnEntry } from '../../../domain/zone';
import { ZoneMap } from '../../../components/map/ZoneMap';
import { Polygon } from '../../../domain/map';

function ZoneList() {
  const { toastInfo, toastError } = useToast();
  const { startLoading, endLoading } = useLoading();
  const { token } = useAuth();
  const { state, setZones, setSelectedZone, setZoneSpawnEntries, setFocusFilter, setZonePolygons, setFilter, setShowGrid, setShowNpcGrid, toggleEnabledMarker, resetMarker, setPosition, setZoom, setMinZ, setMaxZ } = useZoneReducer();

  const [zonegrids, setZoneGrids] = useState<{key: number, polygon: Polygon}[] | undefined>();

  useEffect(() => {
    const abortController = new AbortController();
    // declare the async data fetching function
    const fetchData = async () => {
      try {
        console.info(token +" fetch " + Date.now())
        const response = await fetch('/zones', {
          headers: {
            withCredentials: "true",
            crossorigin: "true",
            mode: 'no-cors',
            credentials: "include", // include, *same-origin, omit
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: 'Bearer '+ token
          }
        });
        const resultZones = await response.json();
        setZones([{ id: -1, shortName: "Select zone", longName: "Select zone", zoneNumber: -1 }].concat(resultZones));
      } catch (error: any) {
        // ℹ️: The error name is "CanceledError" for Axios.
        if (error.name !== "AbortError") {
          console.log(error);
        }
      }
    }
    // call the function
    fetchData();
    return () => abortController.abort();
  }, [token]);



  useEffect(() => {
    const abortController = new AbortController();
    // declare the async data fetching function
    const fetchData = async () => {
      try {
        if (state.selectedZone.id <= 0 ) {
          setZonePolygons(DefaultZonePolygons);
          setZoneSpawnEntries([]);
          setZoneGrids(undefined);
          return;
        }

        // var zoneLines = await getZoneMapPolyLines(state.selectedZone);
        var zonepolygons = await getZoneMapPolygons(state.selectedZone, token)
        if(!zonepolygons) {
          return;
        }

        setZonePolygons(zonepolygons);

        const response = await fetch(`/zone/${state.selectedZone.id}/spawnentries`, {
          headers: {
            withCredentials: "true",
            crossorigin: "true",
            mode: 'no-cors',
            credentials: "include", // include, *same-origin, omit
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: 'Bearer '+ token
          }
        });
        const resultSpawns = await response.json();
        setZoneSpawnEntries(resultSpawns);

        // const response2 = await fetch(`/zones/${state.selectedZone.id}/grids?unused_only=true`, {
        //   headers: {
        //     withCredentials: "true",
        //     crossorigin: "true",
        //     mode: 'no-cors',
        //     credentials: "include", // include, *same-origin, omit
        //     "Accept": "application/json",
        //     "Content-Type": "application/json",
        //     Authentication: 'Bearer '+ token
        //   }
        // });
        // const grids = await response2.json();
        // setZoneGrids(grids);

      } catch (error: any) {
        // ℹ️: The error name is "CanceledError" for Axios.
        if (error.name !== "AbortError") {
          console.log(error);
        }
      }
    }

    // call the function
    fetchData();
    return () => abortController.abort();
  }, [state.selectedZone]);

  const selectZone = (event: any) => {
    var selectedZone = state.zoneData.find(zone => zone.id === Number(event.target.value))
    setSelectedZone(selectedZone);
  };

  function zoneText(zone: Zone) {
    if (zone.zoneNumber > 0) {
      return `${zone.longName} - ${zone.zoneNumber}`;
    }

    return `${zone.longName}`;
  }

  const selectSpawnGroupFocus = (event: ChangeEvent<HTMLSelectElement>): void => {
    if(Number(event.target.value) === -1) {
      setFocusFilter({})
    }

    setFocusFilter({spawnGroupId: Number(event.target.value)});
  };


  const setMinZChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    if(Number(event.target.value) === -1) {
      setMinZ(0)
    }

    setMinZ(Number(event.target.value));
  };


  const setMaxZChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    if(Number(event.target.value) === -1) {
      setMaxZ(0)
    }

    setMaxZ(Number(event.target.value));
  };

  function selectFilter(event: ChangeEvent<HTMLSelectElement>): void {
    setFilter(Number(event.target.value))
  }

  const toggleShowGrid = () => {
    setShowGrid(!state.showgrid)
  };

  const toggleShowNpcGrid = () => {
    setShowNpcGrid(!state.shownpcgrid)
  };

  const isFocused = (spawnpoint: ZoneSpawnEntry) => {
    return spawnpoint.spawnGroupId == state.focusFilter["spawnGroupId"];
  }

  const getMapMarkerX = () => {
    if (state.clickedPosition === null) {
      return '';
    }

    return state.zonePolygons.converters.mapCoordinatesToEQLoc(state.clickedPosition.lat, state.clickedPosition.lng).x;
  }

  const getMapMarkerY = () => {
    if (state.clickedPosition === null) {
      return '';
    }

    return state.zonePolygons.converters.mapCoordinatesToEQLoc(state.clickedPosition.lat, state.clickedPosition.lng).y;
  }

  return (
    <>
      <div className="container is-fluid">
        <div className="notification">
          <nav className="level" style={{flexWrap: 'wrap'}}>
            <div className="level-left">
              <div className="level-item">
                <div className="field">
                  <div className={`select ${state.zoneData.length === 1 && 'is-loading'}`}>
                    <select value={state.selectedZone.id} onChange={selectZone}>
                      {state.zoneData.map((zone: Zone, index: number) => <option key={index} value={zone.id}>{zoneText(zone)}</option>)}
                    </select>
                  </div>
                </div>
              </div>
              <div className={`level-item ${state.selectedZone === dummyZone ? 'is-hidden' : ''}`}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Spawngroup</label>
                  </div>
                  <div className="field-body">
                    <div className="control">
                      <div className='select'>
                        <select value={state.focusFilter.spawnGroupId ?? -1} onChange={selectSpawnGroupFocus}>
                          <option key={-1} value={-1}>None</option>
                          {state.spawnGroups.map((spawngroup: SpawnGroup, index: number) => <option key={index} value={spawngroup.id}>{spawngroup.name}</option>)}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`level-item ${state.selectedZone === dummyZone ? 'is-hidden' : ''}`}>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Filter</label>
                  </div>
                  <div className="field-body">
                    <div className="control">
                      <div className='select'>
                        <select value={state.filter} onChange={selectFilter}>
                          <option key={1} value={1}>All</option>
                          <option key={2} value={2}>Enabled</option>
                          <option key={3} value={3}>Disabled</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="level-left">
              <div className={`level-item ${state.selectedZone === dummyZone ? 'is-hidden' : ''}`}>
                <div className="field is-horizontal">
                  <input id="switchShowGrid" type="checkbox" name="switchShowGrid" className="switch is-small" checked={state.showgrid} onChange={toggleShowGrid} />
                  <label htmlFor="switchShowGrid" className="is-small">Show grid</label>
                </div>
              </div>
              <div className={`level-item ${state.selectedZone === dummyZone ? 'is-hidden' : ''}`}>
                <div className="field is-horizontal">
                  <input id="switchShowNpcGrid" type="checkbox" name="switchShowNpcGrid" className="switch is-small" checked={state.shownpcgrid} onChange={toggleShowNpcGrid} />
                  <label htmlFor="switchShowNpcGrid" className="is-small">Show npc grid</label>
                </div>
              </div>
              <div className="level-item">
                <div className="field is-horizontal">
                  <input type="range" name="zIndex" className="slider is-fullwidth is-primary is-small" step="1" min={state.zonePolygons.map.bounds[0][2]} max={state.maxZ} value={state.minZ} onChange={setMinZChanged} />
                  <label htmlFor="zIndex" className="is-small">min&#x2011;Z</label>
                </div>
              </div>
              <div className="level-item">
                <div className="field is-horizontal">
                  <input type="range" name="zIndex" className="slider is-fullwidth is-primary is-small" step="1" min={state.minZ} max={state.zonePolygons.map.bounds[1][2]} value={state.maxZ} onChange={setMaxZChanged} />
                  <label htmlFor="zIndex" className="is-small">max&#x2011;Z</label>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="container mt-4">
          <ZoneMap zonePolygons={state.zonePolygons}
                   debugPolygons={zonegrids}
                   zone={state.selectedZone}
                   zoom={state.zoom}
                   spawns={state.filteredSpawns}
                   showgrid={state.showgrid}
                   shownpcgrid={state.shownpcgrid}
                   clickedPosition={state.clickedPosition}
                   setPosition={state.markerEnabled ? setPosition : undefined}
                   setZoom={setZoom}
                   isFocused={isFocused}

          />
        </div>
        <div className="container mt-4">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <p className="subtitle is-5">
                  <strong>Marker</strong>
                </p>
              </div>
              <div className="level-item">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">x</label>
                  </div>
                  <div className="field-body">
                    <div className="control">
                      <input id="markerx" type="number" className='input' name="markerx" value={getMapMarkerX()} readOnly={true} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="level-item">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">y</label>
                  </div>
                  <div className="field-body">
                    <div className="control">
                      <input id="markery" type="number" className='input' name="markery" value={getMapMarkerY()} readOnly={true} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="level-item">
                <button className="button is-success is-light" onClick={toggleEnabledMarker}>{state.markerEnabled ? 'Disable map click' : 'Enable map click'}</button>
              </div>
              <div className="level-item">
                <button className="button is-primary is-light" onClick={resetMarker}>Reset</button>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

function Npcs() {
  return (
    <ZoneProvider>
      <ZoneList />
    </ZoneProvider>
  );
}


export default Npcs;
// coordToMap(280.068299995, 1992.51520473)
// [-130.627056271,98.8942258529]

// 1754, 370, 28 // y, x, z

// conv*y = lat
// -0,0744738063118586

// conv*x = lon
// 0,2672816914943243

// 1648, 376, 28
// [-122,732832801943,100,4979160018659]

/*
            <Circle center={[0,0]} radius={2}/>
            <Circle center={[0,256]} radius={2} />
            <Circle center={[-256,0]} radius={2}/>
            <Circle center={[-256,256]} radius={2}/>

            { renderNpc({x:370, y:1754}) }
            { renderNpc({x:376, y:1648}) }
            <Circle center={coordToMap(-1156, -1314)} radius={1} color='red'/>
*/
