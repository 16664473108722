// https://medium.com/@mohsentaleb/elegantly-type-reacts-usereducer-and-context-api-with-discriminated-union-of-typescript-855ff475cafe

import { ActionFuncMap, assertUnreachable } from "../../../common/utils";
import { PropsWithChildren, createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { dispatcherWrapper } from "../../../common/reducerUtils";

export type ConsolidationNpcType = {
  id: number,
  name: string,
  factionId: number,
  genderId: number,
  lootTableId: number,
  raceId: number,
  classId: number,
  bodyType: number,
  selected?: boolean,
}

export type ConsolidationNpcTypes = {
  [name: string]: ConsolidationNpcType  []
}

export type Zone = {
  id: number,
  shortName: string,
  longName: string,
  zoneNumber: number,
  version: number,
}

enum Types {
  SetConsolidations = 'SET_CONSOLIDATIONS',
  SetZones = 'SET_ZONES',
  SetSelectedZone = 'SET_SELECTED_ZONE',
  MergeSuccess = 'MERGE_SUCCESS',
  Toggle = 'TOGGLE',
  ToggleAll = 'TOGGLE_ALL',
}

type State = {
  consolidationData: ConsolidationNpcTypes;
  zoneData: Zone[];
  selectedZone: Zone;
}

const dummyZone: Zone = { id: -1, shortName: "Select zone", longName: "Select zone", zoneNumber: -1, version: 0 };
const emptyZones: Zone[] = [dummyZone];

// https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm

const reducerActions = {
  setConsolidations: (consolidationData: ConsolidationNpcTypes) => { return { type: Types.SetConsolidations as typeof Types.SetConsolidations, payload: consolidationData } },
  setZones: (zones: Zone[]) => { return { type: Types.SetZones  as typeof Types.SetZones, payload: zones } },
  setSelectedZone: (selectedZone: Zone | undefined) => { return { type: Types.SetSelectedZone as typeof Types.SetSelectedZone, payload: selectedZone } },
  mergeSuccess: (npcType: string, npcIds: number[]) => { return { type: Types.MergeSuccess as typeof Types.MergeSuccess, payload: {npcType, npcIds} } },
  toggle: (npcType: string, consolidationNpcType: ConsolidationNpcType, selected: boolean) => { return { type: Types.Toggle as typeof Types.Toggle, payload: {npcType, consolidationNpcType, selected} } },
  toggleAll: (npcType: string, selected: boolean) => { return { type: Types.ToggleAll as typeof Types.ToggleAll, payload: {npcType, selected} } },
}
type Actions = ActionFuncMap<typeof reducerActions>[keyof ActionFuncMap<typeof reducerActions>];

// https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
const consolidationReducer = (draft: State, action: Actions) => {
  switch (action.type) {
    case Types.SetConsolidations:
      draft.consolidationData = action.payload;
      return draft;
    case Types.SetZones:
      draft.zoneData = action.payload;
      return draft;
    case Types.SetSelectedZone:
      draft.selectedZone = action.payload ?? dummyZone;
      return draft;
    case Types.MergeSuccess:
      const newMergeNpcTypeList = draft.consolidationData[action.payload.npcType].filter(npctype => !action.payload.npcIds.includes(npctype.id))
      if(newMergeNpcTypeList.length === 0) {
        delete draft.consolidationData[action.payload.npcType];
      }
      else {
        draft.consolidationData[action.payload.npcType] = newMergeNpcTypeList;
      }

      return draft;
    case Types.Toggle:
        const newSelectedMergeNpcTypeList = draft.consolidationData[action.payload.npcType].map(consolidationNpcType => {
          if(consolidationNpcType.id === action.payload.consolidationNpcType.id) {
            consolidationNpcType.selected = action.payload.selected;
          }

          return consolidationNpcType;
        });

        draft.consolidationData[action.payload.npcType] = newSelectedMergeNpcTypeList;

        return draft;
    case Types.ToggleAll:
        const newSelectedMergeNpcTypeList2 = draft.consolidationData[action.payload.npcType].map(consolidationNpcType => {
          consolidationNpcType.selected = action.payload.selected;
          return consolidationNpcType;
        });

        draft.consolidationData[action.payload.npcType] = newSelectedMergeNpcTypeList2;

        return draft;
  }

  return assertUnreachable(action);
};


// https://prateeksurana.me/blog/simplify-immutable-data-structures-in-usereducer-with-immer/
const dispacthActions = dispatcherWrapper<Actions, typeof reducerActions>(reducerActions);
const ConsolidationsContext = createContext<ReturnType<typeof dispacthActions> & { state: Readonly<State> }>(null!);

export const ConsolidationsProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useImmerReducer<State, Actions>(
    consolidationReducer,
    {
      consolidationData: {},
      zoneData: emptyZones,
      selectedZone: dummyZone
    }
  );

  const actions = dispacthActions(dispatch);
  const value = {...actions, state};

  return <ConsolidationsContext.Provider value={value}>{props.children}</ConsolidationsContext.Provider>;
};


export const useConsolidationsReducer = () => {
  const currentContext = useContext(ConsolidationsContext);

  if (!currentContext) {
    throw new Error(
      "useConsolidationsReducer has to be used within <ConsolidationsContext.Provider>"
    );
  }

  return currentContext;
};
