import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/fv.jpg';
import { useAuth } from '../hooks/useAuth';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

function NavBar() {
  const { jwtData, token, logout } = useAuth();
  const pathname = useLocation().pathname;
  const [inSync, setInSync] = useState<boolean | null>(null);


  useEffect(() => {
    const abortController = new AbortController();
    // declare the async data fetching function
    const fetchData = async () => {
      try {
        const response = await fetch('/schema/insync', {
          headers: {
            withCredentials: "true",
            crossorigin: "true",
            mode: 'no-cors',
            credentials: "include", // include, *same-origin, omit
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: `Bearer ${token}`
          }
        });
        const resultZones = await response.text();
        setInSync(Boolean(resultZones));
      } catch (error: any) {
        // ℹ️: The error name is "CanceledError" for Axios.
        if (error.name !== "AbortError") {
          console.log(error);
        }
      }
    }
    // call the function
    fetchData();
    return () => abortController.abort();
  }, [token]);



  const schemaSync =  () => {
    var text = "Loading...";
    if(inSync === false) {
      text = "Schema not in sync";
    } else if(inSync === true) {
      text = "Schema is in sync";
    }

    return (
      <div  className={classNames(['status','pl-2', 'pr-2', 'pb-1', 'pt-1', {'has-text-white': inSync !== null}, {'has-background-success': inSync === true}, {'has-background-danger': inSync === false}, {'has-background-warning': inSync === null}])}>
        {text}
      </div>
    );
  }

  const userProfile =  () => {
    if(jwtData.role === 'UnRegistrered') {
      return (
        <div className='navbar-item'>
          <div className="is-relative">
            <span className="icon">
              <FontAwesomeIcon icon={faUser} />
            </span>
          </div>
        </div>
      );
    }

    return (
      <Link to="user/profile" className='navbar-item'>
      <div className="is-relative">
        <span className="icon">
          <FontAwesomeIcon icon={faUser} />
        </span>
      </div>
    </Link>
    );
  }

  const adminTab =  () => {
    if(jwtData.role !== 'Administrator') {
      return (
        <></>
      );
    }

    return (
      <li className={classnames(['', {"is-active": pathname.startsWith("/user/")}])}>
        <Link to="user/list" className='navbar-item'>Users</Link>
      </li>
    );
  }

  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        {schemaSync()}
        <div className="navbar-brand">
          <div className="navbar-item p-0 pl-1">
            <Link to="" className='navbar-item p-0'>
              <figure className="image is-48x48" style={{borderRadius:'10%', overflow:'hidden'}}>
                <img src={logo} alt='logo' />
              </figure>
            </Link>
          </div>
          <div className="navbar-item">
            <div className="container">
              <h1 className="title is-5 ">The Firiona Vie Project</h1>
              <h2 className="subtitle is-7 has-text-grey-dark is-family-code">[Kunark]</h2>
            </div>
          </div>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">

          <div className="navbar-end">
            {userProfile()}
            <a className="navbar-item" onClick={logout}>
              <div className="is-relative">
                <span className="icon">
                  <FontAwesomeIcon icon={faRightFromBracket} />
                </span>
              </div>
            </a>
          </div>
        </div>
      </nav>
      <div className="tabs is-centered">
        <ul>
          <li className={classnames(['', {"is-active": pathname === "/"}])}>
            <Link to="/" className='navbar-item'>Dashboard</Link>
          </li>
          <li className={classnames(['', {"is-active": pathname.startsWith("/npcs/")}])}>
            <Link to="npcs/list" className='navbar-item'>Npcs</Link>
          </li>
          <li className={classnames(['', {"is-active": pathname.startsWith("/zone/")}])}>
            <Link to="zone/list" className='navbar-item'>Zone</Link>
          </li>
          {adminTab()}
        </ul>
      </div>
    </>
  );
}

export default NavBar;
function useRouteMatch(arg0: string) {
  throw new Error('Function not implemented.');
}

