// https://medium.com/@mohsentaleb/elegantly-type-reacts-usereducer-and-context-api-with-discriminated-union-of-typescript-855ff475cafe

import { ActionFuncMap, assertUnreachable } from "../../../common/utils";
import { PropsWithChildren, createContext, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { dispatcherWrapper } from "../../../common/reducerUtils";

enum Types {
  SetUserName = 'SET_USER_NAME',
  SetEmail = 'SET_EMAIL',
  SetRole = 'SET_ROLE',
  SetNewPassword = 'SET_NEW_PASSWORD',
  SetVerifyNewPassword = 'SET_VERIFY_NEW_PASSWORD',
}

type AddState = {
  userName: string;
  email: string;
  role: number;
  newPassword: string;
  verifyNewPassword: string;
}

// https://dev.to/elisealcala/react-context-with-usereducer-and-typescript-4obm

const reducerActions = {
  setUserName: (value: string) => { return {type: Types.SetUserName as typeof Types.SetUserName, payload: value} },
  setEmail: (value: string) => { return {type: Types.SetEmail as typeof Types.SetEmail, payload: value} },
  setRole: (value: number) => { return {type: Types.SetRole as typeof Types.SetRole, payload: value} },
  setNewPassword: (value: string) => { return {type: Types.SetNewPassword as typeof Types.SetNewPassword, payload: value} },
  setVerifyNewPassword: (value: string) => { return {type: Types.SetVerifyNewPassword as typeof Types.SetVerifyNewPassword, payload: value} },
}
type AddActions = ActionFuncMap<typeof reducerActions>[keyof ActionFuncMap<typeof reducerActions>];

// https://stackoverflow.com/questions/39419170/how-do-i-check-that-a-switch-block-is-exhaustive-in-typescript
const addReducer = (draft: AddState, action: AddActions) => {
  switch (action.type) {
    case Types.SetUserName:
      draft.userName = action.payload;
      return draft;
    case Types.SetEmail:
      draft.email = action.payload;
      return draft;
    case Types.SetRole:
      draft.role = action.payload;
      return draft;
    case Types.SetNewPassword:
      draft.newPassword = action.payload;
      return draft;
    case Types.SetVerifyNewPassword:
      draft.verifyNewPassword = action.payload;
      return draft;
  }

  return assertUnreachable(action);
};


// https://prateeksurana.me/blog/simplify-immutable-data-structures-in-usereducer-with-immer/
const dispacthActions = dispatcherWrapper<AddActions, typeof reducerActions>(reducerActions);
const AddContext = createContext<ReturnType<typeof dispacthActions> & { state: Readonly<AddState> }>(null!);

export const AddProvider = (props: PropsWithChildren<{}>) => {
  const [state, dispatch] = useImmerReducer<AddState, AddActions>(
    addReducer,
    {
      userName: "",
      email: "",
      role: 1,
      newPassword: "",
      verifyNewPassword: ""
    }
  );

  const actions = dispacthActions(dispatch);
  const value = {...actions, state};

  return <AddContext.Provider value={value}>{props.children}</AddContext.Provider>;
};


export const useAddReducer = () => {
  const currentContext = useContext(AddContext);

  if (!currentContext) {
    throw new Error(
      "useAddReducer has to be used within <AddContext.Provider>"
    );
  }

  return currentContext;
};
