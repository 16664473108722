import { Polygon } from "./map"

export type Zone = {
  id: number,
  shortName: string,
  longName: string,
  zoneNumber: number
}


export type SpawnEntryNpcType = {
  npcId: number,
  npcName: string,
  spawnChance: number,
  minExpansion: number,
  maxExpansion: number,
  contentFlags: string
}

export type ZoneSpawnEntry = {
  id: number,
  spawnGroupId: number,
  spawnGroupName: string,
  x: number,
  y: number,
  z: number,
  enabled: number,
  minExpansion: number,
  maxExpansion: number,
  contentFlags: string
  npcTypes: SpawnEntryNpcType[],
  pathGrid: Polygon
}

export const isEnabled = (spawnpoint: ZoneSpawnEntry) => {
  if(spawnpoint.enabled == 0) return false;
  if (spawnpoint.minExpansion > -1 && spawnpoint.minExpansion > 1) return false;
  if (spawnpoint.maxExpansion > -1 && spawnpoint.maxExpansion < 1) return false;

  return true;
}
