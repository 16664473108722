import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLogger } from '../../../hooks/useLogger';
import { useAuth } from '../../../hooks/useAuth';
import { useToast } from '../../../hooks/useToast';
import { useLoading } from '../../../hooks/useLoading';
import { ProfileProvider, useProfileReducer } from './profileReducer';

function ProfileForm() {
  const logger = useLogger();
  const { toastError, toastSuccess } = useToast();
  const { startLoading, endLoading } = useLoading();
  const { token, jwtData } = useAuth();
  const { setOldPassword, setNewPassword, setVerifyNewPassword, state } = useProfileReducer();

  const updatePassword = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    startLoading();
    try {
      var result = await fetch(`/users/${jwtData.sub}`,
        {
          headers:{
            method: "PATCH", // *GET, POST, PUT, DELETE, etc.
            "Accept": "application/json",
            "Content-Type": "application/json",
            Authentication: `Bearer ${token}`
          },
          method:'POST',
          body:JSON.stringify({oldPassword: state.oldPassword, newPassword: state.newPassword, verifyNewPassword: state.verifyNewPassword})
        });

      if(!result.ok) {
        var errorMsg = result.text();
        toastError(`Failed to update password: ${errorMsg}`);
        throw new Error(`Request failed with error code '${result.status}'`);
      }

      toastSuccess("Updated password.");
    } catch (e){
      // https://stackoverflow.com/questions/54649465/how-to-do-try-catch-and-finally-statements-in-typescript
      // https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
      logger.logError((e as Error).message);
    }
    endLoading();
  };

  const canTryUpdatePassword = () => state.oldPassword !== "" && state.newPassword !== "" && state.newPassword === state.verifyNewPassword;

  return (
    <div className="container mt-5">
      <h2 className='title has-text-centered'>Change password</h2>
      <form>
        <div className="field">
          <label className="label">Old password</label>
          <p className="control has-icons-left">
            <input className="input" placeholder="password" id="oldpassword" type="password" value={state.oldPassword ?? ""} onChange={(event) => { setOldPassword(event.target.value); }} />
            <span className="icon is-left is-small">
              <FontAwesomeIcon icon={faKey} />
            </span>
          </p>
        </div>
        <div className="field">
          <label className="label">New password</label>
          <p className="control has-icons-left">
            <input className="input" placeholder="password" id="newpassword" type="password" value={state.newPassword ?? ""} onChange={(event) => { setNewPassword(event.target.value); }} />
            <span className="icon is-left is-small">
              <FontAwesomeIcon icon={faKey} />
            </span>
          </p>
        </div>
        <div className="field">
          <label className="label">Verify password</label>
          <p className="control has-icons-left">
            <input className="input" placeholder="password" id="verifypassword" type="password" value={state.verifyNewPassword ?? ""} onChange={(event) => { setVerifyNewPassword(event.target.value); }} />
            <span className="icon is-left is-small">
              <FontAwesomeIcon icon={faKey} />
            </span>
          </p>
        </div>
        <button className="button is-primary" disabled={!canTryUpdatePassword()} onClick={(updatePassword)} >Update</button>
      </form>
    </div>
  );
}

function Profile() {
  return (
    <ProfileProvider>
      <ProfileForm />
    </ProfileProvider>
  );
}

export default Profile;
