// https://medium.com/@martin_hotell/redux-typescript-typed-actions-with-less-keystrokes-d984063901d
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type ActionFuncMap<M extends { [index: string]: (...args: any) => any }> = {
  [Key in keyof M]: ReturnType<M[Key]>
};

export const assertUnreachable = (x: never): never => {
  throw new Error("Should never get here");
}
